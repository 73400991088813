import React from 'react'
import PropTypes from 'prop-types'

import BlacklistTable from '@components/blacklisttable'

export const frontmatter = {
  title: 'Gesperrte Nutzer',
}

function BannedPage() {
  return <BlacklistTable />
}

BannedPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default BannedPage
