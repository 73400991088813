import React, { useEffect, useState, useRef } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import { DeleteForeverRounded } from '@material-ui/icons'
import { Box } from '@material-ui/core'

import LoadingSkeleton from '@objects/loadingSkeleton'
import Table from '@objects/table'
import useSnackbar from '@hooks/useSnackbar'
import { getBannedUsers, removeUserFromBannList } from '@services/userservice'

const tableHeader = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'readion', label: 'Grund' },
  { id: 'date', label: 'Datum der Sperrung' },
  { id: 'actions', label: 'Aktionen' },
]

function BlacklistTable() {
  const isMountedRef = useRef(null)
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true)
  const { toggleSnackbar } = useSnackbar()
  const tableActions = [
    {
      title: 'Von der Liste löschen',
      icon: <DeleteForeverRounded />,
      onClick: (row) => {
        rowDelete(row.id)
      },
    },
  ]

  useEffect(() => {
    isMountedRef.current = true
    refreshList()

    return () => {
      isMountedRef.current = false
    }
  }, [])

  function refreshList() {
    setLoading(true)
    setUserList([])
    getBannedUsers()
      .then((users) => {
        if (isMountedRef.current) {
          setUserList(
            users.map((user) => ({
              id: user.id,
              items: [
                { label: user.name },
                { label: user.email },
                { label: user.reason },
                {
                  label: moment(user.timestamp)
                    .locale('de')
                    .format('DD.MM.YYYY HH:mm'),
                },
              ],
            }))
          )
          setLoading(false)
        }
      })
      .catch((e) => {
        console.error(e)
        toggleSnackbar(
          `Bitte wenden Sie sich an einen Administrator - ${e}`,
          'error'
        )
      })
  }

  function rowDelete(id) {
    removeUserFromBannList(id).then(() => refreshList())
  }

  return (
    <Box mt={8}>
      <LoadingSkeleton loading={loading} type="table">
        <Table heads={tableHeader} rows={userList} actions={tableActions} />
      </LoadingSkeleton>
    </Box>
  )
}

BlacklistTable.propTypes = {}

export default BlacklistTable
